import './nav.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { signout } from '../auth/actions'
import Options from './components/options'
import { Layout, Menu } from 'antd'
const { Sider } = Layout

export class Nav extends Component {
    render() {
        const { signout, location, auth, close } = this.props
        const authenticated = auth.isLoaded && !auth.isEmpty

        return (
            <div className="nav">
                <Sider theme="dark" className="sider">
                    {authenticated && <div>
                        <Options email={auth.email} signout={signout} />
                        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
                            <Menu.Item key="/metrics">
                                <Link to='/metrics' onClick={close}>
                                    <span className="nav-text">Metrics</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/submissions">
                                <Link to='/submissions' onClick={close}>
                                    <span className="nav-text">Submissions</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/feedback">
                                <Link to='/feedback' onClick={close}>
                                    <span className="nav-text">Feedback</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/topics">
                                <Link to='/topics' onClick={close}>
                                    <span className="nav-text">Topics</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/items">
                                <Link to='/items' onClick={close}>
                                    <span className="nav-text">Items</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>}
                </Sider>
            </div>
        )
    }
}

const mapState = state => ({
    auth: state.firebase.auth
})

const actions = { signout }

export default withRouter(connect(mapState, actions)(Nav))
