import { SubmissionError } from 'redux-form'
import history from '../common/tools/history'
import { SystemError } from '../common/tools/error'

const emails = ['h@veronode.com', 'harrison0723@gmail.com']

export const login = (creds) => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            if (creds.email && !emails.includes(creds.email)) {
                const error = { code: "auth/no-access" }
                throw error
            }
            await getFirebase().auth().signInWithEmailAndPassword(creds.email, creds.password)
            history.push('/metrics')
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Something's missing..." })
            if (error.code === "auth/no-access") throw new SubmissionError({ _error: "You don't have access." })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signup = (creds) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        try {
            if (creds.email && !emails.includes(creds.email)) {
                const error = { code: "auth/no-access" }
                throw error
            }
            // Create the user in Firebase
            let userForFirebase = await getFirebase().auth().createUserWithEmailAndPassword(creds.email, creds.password)
            // Create the user in Firestore
            let userForFirestore = {
                name: creds.name,
                email: creds.email,
                neurons: 0,
                nutcoins: 100,
                items: [],
                guest: false,
                createdAt: getFirestore().FieldValue.serverTimestamp()
            }
            await getFirestore().collection('users').doc(userForFirebase.user.uid).set(userForFirestore)
            history.push('/metrics')
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Something's missing..." })
            if (error.code === "auth/no-access") throw new SubmissionError({ _error: "You don't have access." })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signout = () => {
    return async (dispatch, getState, { getFirebase }) => {
        try {
            await getFirebase().auth().signOut()
            history.push('/')
        } catch (error) {
            SystemError(error)
        }
    }
}